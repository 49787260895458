<template>
  <div class="view-home" style="background-color: #FAFAFB;">
    <div class="page-container">
      <div class="title-block">
        <h1>{{ $t('registry.statistics') }}</h1>
      </div>
      <el-select v-model="value" :placeholder="$t('registry.for-period')" class="select-period">
        <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
      <div class="kids-block">
        <div class="age-distribution">
          <div class="title">{{ $t('registry.number-children') }}</div>
          <div class="number-kids">16.5 тыс.</div>
          <div class="explanation">{{ $t('registry.total-number') }}</div>
          <div class="title">{{ $t('registry.age-distribution') }}</div>
          <div class="ages-list">
            <div class="ages-list-item">
              <div class="age-range">
                7-8 лет
              </div>
              <el-progress :percentage="50" status="" class="kids-progress-bar"></el-progress>
              <div class="kids-number">
                16.2 тыс
              </div>
            </div>
            <div class="ages-list-item">
              <div class="age-range">
                9-10 лет
              </div>
              <el-progress :percentage="50" status="" class="kids-progress-bar"></el-progress>
              <div class="kids-number">
                16.2 тыс
              </div>
            </div>
            <div class="ages-list-item">
              <div class="age-range">
                11-12 лет
              </div>
              <el-progress :percentage="50" status="" class="kids-progress-bar"></el-progress>
              <div class="kids-number">
                16.2 тыс
              </div>
            </div>
            <div class="ages-list-item">
              <div class="age-range">
                12-14 лет
              </div>
              <el-progress :percentage="50" status="" class="kids-progress-bar"></el-progress>
              <div class="kids-number">
                16.2 тыс
              </div>
            </div>
            <div class="ages-list-item">
              <div class="age-range">
                15-17 лет
              </div>
              <el-progress :percentage="50" status="" class="kids-progress-bar"></el-progress>
              <div class="kids-number">
                16.2 тыс
              </div>
            </div>
          </div>
        </div>
        <div class="scientific-achievements">
          <div class="d-flex justify-content-between">
            <div class="title">{{ $t('registry.age') }}</div>
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" @click="dialogVisible=true">
              <circle cx="11.0007" cy="10.9997" r="0.916667" stroke="#4B465C" stroke-width="1.75" stroke-linecap="round"
                      stroke-linejoin="round"/>
              <circle cx="11.0007" cy="10.9997" r="0.916667" stroke="white" stroke-opacity="0.5" stroke-width="1.75"
                      stroke-linecap="round" stroke-linejoin="round"/>
              <circle cx="11.0007" cy="17.4167" r="0.916667" stroke="#4B465C" stroke-width="1.75" stroke-linecap="round"
                      stroke-linejoin="round"/>
              <circle cx="11.0007" cy="17.4167" r="0.916667" stroke="white" stroke-opacity="0.5" stroke-width="1.75"
                      stroke-linecap="round" stroke-linejoin="round"/>
              <ellipse cx="11.0007" cy="4.58366" rx="0.916667" ry="0.916667" stroke="#4B465C" stroke-width="1.75"
                       stroke-linecap="round" stroke-linejoin="round"/>
              <ellipse cx="11.0007" cy="4.58366" rx="0.916667" ry="0.916667" stroke="white" stroke-opacity="0.5"
                       stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <div class="explanation">{{ $t('registry.number-scientific-achievements') }}</div>
          <svg width="300" height="302" viewBox="0 0 300 302" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="102" cy="102" r="102" fill="#0F60FF"/>
            <text x="102" y="102" text-anchor="middle" dominant-baseline="middle" fill="white" font-family="Arial">
              <tspan x="102" dy="-10" class="circle-title">13 лет</tspan>
              <tspan x="102" dy="20" class="circle-explanation">3 167 достижений</tspan>
            </text>
            <circle cx="217" cy="185" r="83" fill="#0FB7FF"/>
            <text x="217" y="185" text-anchor="middle" dominant-baseline="middle" fill="white" font-family="Arial">
              <tspan x="217" dy="-10" class="circle-title">9 лет</tspan>
              <tspan x="217" dy="20" class="circle-explanation">1 167 достижений</tspan>
            </text>
            <circle cx="102" cy="240" r="62" fill="#1EB564"/>
            <text x="102" y="240" text-anchor="middle" dominant-baseline="middle" fill="white" font-family="Arial">
              <tspan x="102" dy="-8" class="circle-title">16 лет</tspan>
              <tspan x="102" dy="18" class="circle-explanation">167 достижений</tspan>
            </text>
          </svg>
        </div>
        <div class="passed-test">
          <div class="passed-test-item">
            <div class="passed-test-item-top">
              <div class="passed-test-item-details">
                <div class="test-description">{{ $t('registry.passed-test') }}</div>
                <div class="test-count">316.5 тыс.</div>
              </div>
              <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z" fill="#0FB7FF"/>
                <rect width="48" height="48" rx="8" fill="#0FB7FF"/>
                <path d="M24.25 15.1084C19.202 15.1084 15.1094 19.201 15.1094 24.249C15.1094 29.297 19.202 33.3896 24.25 33.3896C29.298 33.3896 33.3906 29.297 33.3906 24.249C33.3906 19.201 29.298 15.1084 24.25 15.1084Z" fill="#0FB7FF" stroke="white" stroke-width="0.75" stroke-miterlimit="10"/>
                <path d="M24.2501 15.1084C21.6982 15.1084 19.2988 19.201 19.2988 24.249C19.2988 29.297 21.6982 33.3896 24.2501 33.3896C26.802 33.3896 29.2015 29.297 29.2015 24.249C29.2015 19.201 26.802 15.1084 24.2501 15.1084Z" fill="#0FB7FF" stroke="white" stroke-width="0.75" stroke-miterlimit="10"/>
                <path d="M18.1562 18.1562C19.8367 19.3494 21.9522 20.0608 24.2501 20.0608C26.548 20.0608 28.6636 19.3494 30.344 18.1562" fill="#0FB7FF"/>
                <path d="M18.1562 18.1562C19.8367 19.3494 21.9522 20.0608 24.2501 20.0608C26.548 20.0608 28.6636 19.3494 30.344 18.1562" stroke="white" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M30.344 30.344C28.6636 29.1509 26.548 28.4395 24.2501 28.4395C21.9522 28.4395 19.8367 29.1509 18.1562 30.344" stroke="white" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M24.25 15.1084V33.3896" stroke="white" stroke-width="0.75" stroke-miterlimit="10"/>
                <path d="M33.3906 24.249H15.1094" stroke="white" stroke-width="0.75" stroke-miterlimit="10"/>
              </svg>
            </div>
            <div class="passed-test-item-bottom">
              <div class="test-percent">
                +5.20%
              </div>
              <div class="test-percent-text">
                {{ $t('registry.compared-last-year') }}
              </div>
            </div>
          </div>
          <div class="passed-test-item">
            <div class="passed-test-item-top">
              <div class="passed-test-item-details">
                <div class="test-description">{{ $t('registry.boys') }}</div>
                <div class="test-count">150 тыс</div>
              </div>
              <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z" fill="#0FB7FF"/>
                <rect width="48" height="48" rx="8" fill="#0FB7FF"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M32.457 16.334C32.6427 16.334 32.8114 16.409 32.9352 16.5309C33.057 16.6546 33.132 16.8234 33.132 17.009V24.059C33.132 24.4321 32.8302 24.734 32.457 24.734C32.0839 24.734 31.782 24.4321 31.782 24.059V17.684H25.407C25.0339 17.684 24.732 17.3821 24.732 17.009C24.732 16.6359 25.0339 16.334 25.407 16.334H32.457ZM22.332 21.134C19.0189 21.134 16.332 23.8209 16.332 27.134C16.332 30.4471 19.0189 33.134 22.332 33.134C25.6452 33.134 28.332 30.4471 28.332 27.134C28.332 23.8209 25.6452 21.134 22.332 21.134ZM22.332 31.784C19.7633 31.784 17.682 29.7027 17.682 27.134C17.682 24.5652 19.7633 22.484 22.332 22.484C24.9008 22.484 26.982 24.5652 26.982 27.134C26.982 29.7027 24.9008 31.784 22.332 31.784Z" fill="white"/>
              </svg>
            </div>
            <div class="passed-test-item-bottom">
              <div class="test-percent">
                +5.20%
              </div>
              <div class="test-percent-text">
                {{ $t('registry.compared-last-year') }}
              </div>
            </div>
          </div>
          <div class="passed-test-item">
            <div class="passed-test-item-top">
              <div class="passed-test-item-details">
                <div class="test-description">{{ $t('registry.girls') }}</div>
                <div class="test-count">166.5 тыс</div>
              </div>
              <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z" fill="#0FB7FF"/>
                <rect width="48" height="48" rx="8" fill="#0FB7FF"/>
                <g clip-path="url(#clip0_2841_6198)">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M25 26.5C28.7289 26.5 31.75 23.4789 31.75 19.75C31.75 16.0211 28.7289 13 25 13C21.2711 13 18.25 16.0211 18.25 19.75C18.25 23.4789 21.2711 26.5 25 26.5ZM25 14.6875C27.7961 14.6875 30.0625 16.9539 30.0625 19.75C30.0625 22.5461 27.7961 24.8125 25 24.8125C22.2039 24.8125 19.9375 22.5461 19.9375 19.75C19.9375 16.9539 22.2039 14.6875 25 14.6875ZM16 28.8438C16 28.3773 16.3773 28 16.8438 28H33.1562C33.6227 28 34 28.3773 34 28.8438C34 29.3102 33.6227 29.6875 33.1562 29.6875H25.8438V36.1562C25.8438 36.6227 25.4664 37 25 37C24.5336 37 24.1562 36.6227 24.1562 36.1562V29.6875H16.8438C16.3773 29.6875 16 29.3102 16 28.8438Z" fill="white"/>
                </g>
                <defs>
                  <clipPath id="clip0_2841_6198">
                    <rect width="24" height="24" fill="white" transform="translate(13 13)"/>
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div class="passed-test-item-bottom">
              <div class="test-percent">
                +5.20%
              </div>
              <div class="test-percent-text">
                {{ $t('registry.compared-last-year') }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="distribution-by-regions">
        <div class="chart-block">
          <div class="title">
            {{ $t('registry.distribution-regions') }}
          </div>
          <div class="chart-container">
            <canvas ref="barChart"></canvas>
          </div>
        </div>
        <div class="regions-container">
          <el-select v-model="value" :placeholder="$t('registry.for-period')" class="regions-select">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>

          <div class="table-wrapper">
            <table>
              <thead>
              <tr>
                <th>№</th>
                <th>{{ $t('registry.region') }}</th>
                <th>{{ $t('registry.quantity') }}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in data" :key="index" class="table-row">
                <td>{{ index + 1 }}</td>
                <td>{{ item.region }}</td>
                <td>{{ item.count }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="distribution-by-subjects">
        <div class="subjects-block">
          <svg width="454" height="454" viewBox="0 0 454 454" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="151.333" cy="151.333" r="151.333" fill="#1EB564"/>
            <circle cx="302.667" cy="151.333" r="151.333" fill="#0FB7FF"/>
            <circle cx="151.333" cy="302.666" r="151.333" fill="#16DBCC"/>
            <circle cx="302.667" cy="302.666" r="151.333" fill="#0F60FF"/>
            <path opacity="0.25" d="M227.001 282.421C272.234 256.255 302.667 207.348 302.667 151.334C302.667 95.319 272.234 46.4123 227.001 20.2461C181.767 46.4123 151.334 95.319 151.334 151.334C151.334 207.348 181.767 256.255 227.001 282.421Z" fill="white"/>
            <path opacity="0.25" d="M227.001 433.754C272.234 407.588 302.667 358.681 302.667 302.667C302.667 246.652 272.234 197.745 227.001 171.579C181.767 197.745 151.334 246.652 151.334 302.667C151.334 358.681 181.767 407.588 227.001 433.754Z" fill="white"/>
            <path opacity="0.75" d="M20.2461 227C46.4123 181.766 95.319 151.333 151.334 151.333C207.348 151.333 256.255 181.766 282.421 227C256.255 272.233 207.348 302.666 151.334 302.666C95.319 302.666 46.4123 272.233 20.2461 227Z" fill="#1EB564"/>
            <path opacity="0.3" d="M171.578 227C197.744 181.766 246.651 151.333 302.666 151.333C358.68 151.333 407.587 181.766 433.753 227C407.587 272.233 358.68 302.666 302.666 302.666C246.651 302.666 197.744 272.233 171.578 227Z" fill="#1EB564"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M226.997 171.579C249.972 184.869 269.129 204.026 282.419 227C269.129 249.975 249.972 269.131 226.997 282.421C204.023 269.131 184.866 249.975 171.576 227C184.866 204.026 204.023 184.869 226.997 171.579Z" fill="white"/>

            <text x="226.997" y="220" text-anchor="middle" alignment-baseline="middle" font-size="16" fill="#0F60FF">
              <tspan x="226.997" dy="0" class="subjects-center">по всем 4к</tspan>
              <tspan x="226.997" dy="30" class="subjects-center">100</tspan>
            </text>
            <text x="90" y="90" text-anchor="middle" alignment-baseline="middle" font-size="16" fill="white">
              <tspan x="102" dy="0" class="subjects-text">Коллаборация</tspan>
              <tspan x="102" dy="30" class="subjects-number">3780</tspan>
              <tspan x="227" dy="0" class="subjects-number">1200</tspan>
              <tspan x="102" dy="120" class="subjects-number">324</tspan>
            </text>
            <text x="370" y="90" text-anchor="middle" alignment-baseline="middle" font-size="16" fill="white">
              <tspan x="370" dy="0" class="subjects-text">Критическое мышление</tspan>
              <tspan x="370" dy="30" class="subjects-number">1780</tspan>
            </text>
            <text x="90" y="330" text-anchor="middle" alignment-baseline="middle" font-size="16" fill="white">
              <tspan x="90" dy="0" class="subjects-text">Коммуникация</tspan>
              <tspan x="90" dy="30" class="subjects-number">3034</tspan>
            </text>
            <text x="370" y="330" text-anchor="middle" alignment-baseline="middle" font-size="16" fill="white">
              <tspan x="370" dy="0" class="subjects-text">Креативность</tspan>
              <tspan x="370" dy="30" class="subjects-number">5005</tspan>
              <tspan x="227" dy="0" class="subjects-number">3400</tspan>
              <tspan x="350" dy="-120" class="subjects-number">110</tspan>
            </text>
          </svg>

          <div class="legend-block">
            <div class="legend-left">
              <div class="legend-item">
                <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="0.5" width="15" height="15" rx="5" fill="#4BC9FF"/>
                </svg>
                Коллаборация+Критическое мышление
              </div>
              <div class="legend-item">
                <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="0.5" width="15" height="15" rx="5" fill="#137AD1"/>
                </svg>
                Критическое мышление+Коммуникация
              </div>
            </div>
            <div class="legend-right">
              <div class="legend-item">
                <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="0.5" width="15" height="15" rx="5" fill="#4B88FF"/>
                </svg>
                Коммуникация+Креативность
              </div>
              <div class="legend-item">
                <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="0.5" width="15" height="15" rx="5" fill="#1CBF7E"/>
                </svg>
                Коллаборация+Креативность
              </div>
            </div>
          </div>
        </div>
        <div class="number-interests">
          <div class="title">
            {{ $t('registry.total-number-scientific-interests-achievements') }}
          </div>
          <div class="number">
            350 тыс.
          </div>
          <div class="add-info">
            <div class="number">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.99974 2.94967C8.08863 2.94967 8.17196 2.96345 8.24974 2.99101C8.32752 3.01856 8.39974 3.0659 8.46641 3.13301L12.8664 7.53301C12.9997 7.66634 13.0664 7.82456 13.0664 8.00767C13.0664 8.19079 12.9997 8.34923 12.8664 8.48301C12.7331 8.61634 12.5775 8.68301 12.3997 8.68301C12.222 8.68301 12.0664 8.61634 11.9331 8.48301L8.66641 5.21634L8.66641 12.683C8.66641 12.8719 8.60241 13.0275 8.47441 13.1497C8.34641 13.2719 8.18818 13.333 7.99974 13.333C7.81085 13.333 7.65241 13.269 7.52441 13.141C7.39641 13.013 7.33263 12.8548 7.33307 12.6663L7.33307 5.21634L4.06641 8.48301C3.93307 8.61634 3.77752 8.68301 3.59974 8.68301C3.42196 8.68301 3.26641 8.61634 3.13307 8.48301C2.99974 8.34967 2.93307 8.19123 2.93307 8.00767C2.93307 7.82412 2.99974 7.6659 3.13307 7.53301L7.53307 3.13301C7.59974 3.06634 7.67196 3.01901 7.74974 2.99101C7.82752 2.96301 7.91085 2.94923 7.99974 2.94967Z" fill="#1EB564"/>
              </svg>
              8.56 тыс.
            </div>
            <div class="title">
              по сравнению с предыдущим годом
            </div>
          </div>

          <div class="line-container">
            <canvas ref="lineChart"></canvas>
          </div>
        </div>
      </div>

      <el-dialog
          :visible.sync="dialogVisible"
          width="30%"
          class="scientific-achievements-modal"
          :before-close="handleClose">
        <template #title>
          <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_4929_9)">
              <rect x="2" y="1" width="48" height="48" rx="10" fill="white"/>
              <rect x="2.5" y="1.5" width="47" height="47" rx="9.5" stroke="#E4E7EC"/>
              <path d="M30 31L32 33L36 29M26 28H22C20.1362 28 19.2044 28 18.4693 28.3045C17.4892 28.7105 16.7105 29.4892 16.3045 30.4693C16 31.2044 16 32.1362 16 34M29.5 16.2908C30.9659 16.8841 32 18.3213 32 20C32 21.6787 30.9659 23.1159 29.5 23.7092M27.5 20C27.5 22.2091 25.7091 24 23.5 24C21.2909 24 19.5 22.2091 19.5 20C19.5 17.7909 21.2909 16 23.5 16C25.7091 16 27.5 17.7909 27.5 20Z" stroke="#344054" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
              <filter id="filter0_d_4929_9" x="0" y="0" width="52" height="52" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="1"/>
                <feGaussianBlur stdDeviation="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4929_9"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4929_9" result="shape"/>
              </filter>
            </defs>
          </svg>
          <div>
            <div class="dialog-title">
              Количество научных достижений
            </div>
            <div class="dialog-subtitle">
              по возрастам
            </div>
          </div>
        </template>
        <div class="dialog-body-title">Возраст</div>
        <div class="age-counter-block">
          <button class="dialog-btn" @click="numberCounter--">
            <img src="/images/registry/minus-btn.svg" width="48" height="49" alt="minus-btn">
          </button>
          <div class="age-counter-number">
            {{numberCounter}}
          </div>
          <button class="dialog-btn" @click="numberCounter++">
            <img src="/images/registry/plus-btn.svg" width="48" height="49" alt="plus-btn">
          </button>

        </div>
        <div class="explanations-block">
          <div class="title">
            Всего достижений
          </div>
          <div class="number">
            3167
          </div>
        </div>
        <div class="explanations-block">
          <div class="title">
            Почетных мест
          </div>
          <div class="number">
            320
          </div>
        </div>
      </el-dialog>

    </div>
  </div>
</template>
<script>
import Chart from "chart.js";

export default {
  data() {
    return {
      numberCounter: 12,
      dialogVisible: false,
      options: [{
        value: 'Option1',
        label: 'Option1'
      }, {
        value: 'Option2',
        label: 'Option2'
      }, {
        value: 'Option3',
        label: 'Option3'
      }, {
        value: 'Option4',
        label: 'Option4'
      }, {
        value: 'Option5',
        label: 'Option5'
      }],
      value: '',
      chartData: {
        labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
        datasets: [
          {
            label: "Количество школьников",
            backgroundColor: "#E9E7FD",
            hoverBackgroundColor: "#0F60FF",
            borderWidth: 1,
            data: [12000, 19000, 3000, 5000, 2000, 3000, 10000, 15000, 20000, 30000, 22000, 25000, 18000, 10000, 7000, 8000, 15000, 19000, 24000, 30000,],
          },
        ],
      },
      chartOptions: {
        legend: {
          display: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              scaleLabel: {
                display: false,
                labelString: "Номера",
              },
            },
          ],
          yAxes: [
            {
              scaleLabel: {
                display: true,
              },
              ticks: {
                callback: (value) => value.toLocaleString("ru-RU"),
              },
            },
          ],
        },
      },
      data: [
        { region: "Актюбинская область", count: 150 },
        { region: "Алматинская область", count: 120 },
        { region: "Атырауская область", count: 80 },
        { region: "Восточно-Казахстанская область", count: 70 },
        { region: "Жамбылская область", count: 60 },
        { region: "Западно-Казахстанская область", count: 55 },
        { region: "Карагандинская область", count: 45 },
        { region: "Костанайская область", count: 40 },
        { region: "Кызылординская область", count: 35 },
        { region: "Мангистауская область", count: 30 },
        { region: "Павлодарская область", count: 25 },
        { region: "Северо-Казахстанская область", count: 20 },
        { region: "Туркестанская область", count: 18 },
        { region: "Улытауская область", count: 16 },
        { region: "Южно-Казахстанская область", count: 14 },
        { region: "Абайская область", count: 12 },
        { region: "Жетысуская область", count: 12 },
        { region: "Шымкент", count: 10 },
        { region: "Астана", count: 8 },
        { region: "Алматы", count: 6 },
      ],
      chartDataLine: {
        labels: ["2019", "2020", "2021", "2022", "2023", "2024", "2025"],
        datasets: [
          {
            label: "Линия 1",
            data: [10, 12, 20, 21, 23, 25, 28],
            borderColor: "#0F60FF",
            backgroundColor: "rgba(75, 192, 192, 0.2)",
            borderWidth: 4,
            fill: false,
            pointRadius: 0,
          },
          {
            label: "Линия 2",
            data: [2, 7, 14, 15, 18, 20, 22],
            borderColor: "#0FB7FF",
            backgroundColor: "rgba(255, 99, 132, 0.2)",
            borderWidth: 4,
            fill: false,
            pointRadius: 0,
          },
        ],
      },
      chartOptionsLine: {
        legend: {
          display: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              position: "top",
              scaleLabel: {
                display: false,
                labelString: "Года",
              },
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              display: false,
              scaleLabel: {
                display: false,
                labelString: "Значения",
              },
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
        tooltips: {
          enabled: true,
        },
      },

    }
  },
  methods: {
    renderChart() {
      const ctx = this.$refs.barChart.getContext("2d");
      new Chart(ctx, {
        type: "bar",
        data: this.chartData,
        options: this.chartOptions,
      });
    },
    renderChartLine() {
      const ctx = this.$refs.lineChart.getContext("2d");
      new Chart(ctx, {
        type: "line",
        data: this.chartDataLine,
        options: this.chartOptionsLine
      });
    },
  },
  mounted() {
    this.renderChart();
    this.renderChartLine();
  },
}
</script>

<style>



</style>